#not-found {
    display: flex;
    flex-flow: column;
    text-align: center;

    h1 {
        margin: 0;
    }

    p {
        margin: 0;
    }
}
